const d = new Date(); 
d.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
let hh = d.getHours();      
let mm = d.getMinutes();  
let ss = d.getSeconds(); 
let session = "AM";   
if(hh == 0){hh = 12;} 
if(hh > 12){hh = hh - 12;session = "PM";}
hh = (hh < 10) ? "0" + hh : hh; 
mm = (mm < 10) ? "0" + mm : mm;   
ss = (ss < 10) ? "0" + ss : ss; 
// var monthnow = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
const lastyear = d.getFullYear() - 1;  
const yearnow = d.getFullYear();         
var lastmonth0 = d.getMonth(); // 👈️ months are 0-based
var last2month0 = d.getMonth() - 1; // 👈️ months are 0-based
var last3month0 = d.getMonth() - 3; // 👈️ months are 0-based
const monthnow0 = d.getMonth() + 1; // 👈️ months are 0-based
function getDaysInMonth(year, month) {    
  return new Date(year, month, 0).getDate();  
}
const daysInCurrentMonth = getDaysInMonth(yearnow, monthnow0); 
var datenow = String(d.getDate()).padStart(2, '0'); 
var todaydate = yearnow + '-' + monthnow0 + '-' + datenow; 
var dateinserted = yearnow + '-' + monthnow0 + '-' + datenow;
var todaydate2 = yearnow + '-' + monthnow0 + '-';
if(monthnow0 < 10) { var monthnow = "0"+monthnow0;}  
else {var monthnow = monthnow0;}

const appkey    = "a9s8d7bas98d7981273xbasduky8b71o247bai8f";

// const statusweb = "development";
const statusweb = "production";

const appsetup    = "utama";
// const appsetup = "catatanku";
// const appsetup = "tugasku";
// const appsetup = "dompetku";
// const appsetup = "sharefiles"; 

if(statusweb=="development") {
  var baseurlassets   = "https://assets.riplabs.id/";
  // var restapilink  = "http://localhost/Project/restapi2.riplabs.id/"; 
  var restapilink     = "https://restapi2.riplabs.id/"; 
  var baseurl         = "http://localhost:3000/"; 
  var baseurl2        = "localhost:3000";
  var baseurl3        = "localhost:3000/";
}
else if(statusweb=="production") {
  var baseurlassets   = "https://assets.riplabs.id/";
  var restapilink     = "https://restapi2.riplabs.id/"; 
  if(appsetup=="utama") {
    var baseurl         = "https://riplabs.id/"; 
    var baseurl2        = "riplabs.id"; 
    var baseurl3        = "riplabs.id/";
  }
  else if(appsetup=="catatanku") {
    var baseurl         = "https://catatanku.riplabs.id/"; 
    var baseurl2        = "catatanku.riplabs.id"; 
    var baseurl3        = "catatanku.riplabs.id/";
  }
  else if(appsetup=="tugasku") {
    var baseurl         = "https://tugasku.riplabs.id/"; 
    var baseurl2        = "tugasku.riplabs.id"; 
    var baseurl3        = "tugasku.riplabs.id/";
  }
  else if(appsetup=="dompetku") {
    var baseurl         = "https://dompetku.riplabs.id/"; 
    var baseurl2        = "dompetku.riplabs.id"; 
    var baseurl3        = "dompetku.riplabs.id/";
  }
  else if(appsetup=="sharefiles") {
    var baseurl         = "https://sharefiles.riplabs.id/"; 
    var baseurl2        = "sharefiles.riplabs.id"; 
    var baseurl3        = "sharefiles.riplabs.id/";
  }
}

// Base Color 
const basecolor1      = "#2094fc";
const basecolor2      = "#333333";
const basecolor3      = "#fff404";
const basecolor4      = "#ffffff";
const basecolor5      = "#eeeeee";
const basecolor5_2    = "#dddddd";
const basecolor5_3    = "#fefefe";
const basecolor6      = "#555555";
const basecolor7      = "#777777";
const basecolorred    = "#dc2f02";
const basecoloryellow = "#ffea00";
const basecolorgreen  = "#80b918";
const basecolorblue   = "#039ED3";

module.exports = { appsetup, statusweb, restapilink, baseurl, baseurl2, baseurlassets, yearnow, monthnow, datenow, basecolor1, basecolor2, basecolor3, basecolor4, basecolor5, basecolor5_2, basecolor5_3, basecolor6, basecolor7, basecolorred, basecoloryellow, basecolorgreen, basecolorblue, appkey }